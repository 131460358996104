import {
  DiscoveryAdvertiserJobAdvertiserNamesResponse,
  DiscoveryAdvertiserJobFeedQuery,
  DiscoveryAdvertiserJobQueryResponse
} from "@repo/browser-sdk/dist/api";
import { useCallback, useState } from "react";
import { useApi } from "../providers/ApiProvider";

type UseDiscoveryAdvertiserJobsProps = {
  query: DiscoveryAdvertiserJobFeedQuery;
};
const useDiscoveryAdvertiserJobs = ({
  query
}: UseDiscoveryAdvertiserJobsProps) => {
  const {
    jobFeedId,
    advertiserName,
    page,
    take,
    title,
    lowerValue,
    upperValue,
    value,
    cpaType,
    cpaRangeType,
    sortByDirection,
    sortByType
  } = query;
  const [discoveryAdvertiserJobs, setDiscoveryAdvertiserJobs] =
    useState<DiscoveryAdvertiserJobQueryResponse | null>(null);
  const [advertisers, setAdvertisers] =
    useState<DiscoveryAdvertiserJobAdvertiserNamesResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loadingJobs, setLoadingJobs] = useState<boolean>(true);
  const [loadingAdvertisers, setLoadingAdvertisers] = useState<boolean>(false);

  const { client, ready } = useApi();

  const getDiscoveryAdvertiserJobs = useCallback(async (): Promise<void> => {
    if (ready && jobFeedId) {
      setLoadingJobs(true);
      try {
        const jobs = await client.discoveryAdvertiserJob.findAll({
          jobFeedId,
          advertiserName,
          page,
          take,
          title,
          cpaRangeType,
          cpaType,
          lowerValue,
          upperValue,
          value,
          sortByDirection,
          sortByType
        });

        setDiscoveryAdvertiserJobs(jobs);
        setLoadingJobs(false);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
          setLoadingJobs(false);
        }
      }
    }
  }, [
    client,
    ready,
    jobFeedId,
    advertiserName,
    page,
    take,
    title,
    cpaRangeType,
    cpaType,
    lowerValue,
    upperValue,
    value,
    sortByDirection,
    sortByType
  ]);

  const getAdvertiserNames = useCallback(
    async (pageNumber: number, limit: number): Promise<void> => {
      if (ready && jobFeedId) {
        setLoadingAdvertisers(true);
        try {
          const advertisersResponse =
            await client.discoveryAdvertiserJob.getAdvertiserNames({
              jobFeedId,
              page: pageNumber,
              take: limit,
              title,
              cpaRangeType,
              cpaType,
              lowerValue,
              upperValue,
              value
            });

          setAdvertisers(advertisersResponse);
          setLoadingAdvertisers(false);
        } catch (err: unknown) {
          if (err instanceof Error) {
            setError(err.message);
            setLoadingAdvertisers(false);
          }
        }
      }
    },
    [
      client,
      ready,
      jobFeedId,
      title,
      cpaRangeType,
      cpaType,
      lowerValue,
      upperValue,
      value
    ]
  );

  return {
    discoveryAdvertiserJobs,
    error,
    getDiscoveryAdvertiserJobs,
    getAdvertiserNames,
    advertisers,
    loadingJobs,
    loadingAdvertisers
  };
};

export default useDiscoveryAdvertiserJobs;
