import {
  DiscoveryJobFeedDtoInterface,
  JobFeedSource,
  JobFeedType
} from "@repo/browser-sdk/dist/api";

export class JobFeedDto implements DiscoveryJobFeedDtoInterface {
  id = "";
  name = "";
  sourceMappingId?: string | undefined;
  source: JobFeedSource = JobFeedSource.Apploi;
  jobFeedUrl = "";
  jobFeedType: JobFeedType = JobFeedType.Xml;
  firstRecord?: Record<string, string> | undefined;
  lastUpdated?: Date | undefined;
  isRunning: boolean = false;
  errorMessage?: string | undefined;
  isDiscovery: boolean = true;
  containsRedirect?: boolean | undefined;
  createdAt?: Date | undefined;

  constructor(partial?: Partial<DiscoveryJobFeedDtoInterface>) {
    if (partial?.id !== undefined) {
      this.id = partial.id;
    }
    if (partial?.name !== undefined) {
      this.name = partial.name;
    }
    if (partial?.sourceMappingId !== undefined) {
      this.sourceMappingId = partial.sourceMappingId;
    }
    if (partial?.source !== undefined) {
      this.source = partial.source;
    }
    if (partial?.jobFeedUrl !== undefined) {
      this.jobFeedUrl = partial.jobFeedUrl;
    }
    if (partial?.jobFeedType !== undefined) {
      this.jobFeedType = partial.jobFeedType;
    }
    if (partial?.firstRecord !== undefined) {
      this.firstRecord = partial.firstRecord as Record<string, string>;
    }
    if (partial?.lastUpdated !== undefined) {
      this.lastUpdated = partial.lastUpdated;
    }
    if (partial?.isRunning !== undefined) {
      this.isRunning = partial.isRunning;
    }
    if (partial?.errorMessage !== undefined) {
      this.errorMessage = partial.errorMessage;
    }
    if (partial?.isDiscovery !== undefined) {
      this.isDiscovery = partial.isDiscovery;
    }
    if (partial?.containsRedirect !== undefined) {
      this.containsRedirect = partial.containsRedirect;
    }
    if (partial?.createdAt !== undefined) {
      this.createdAt = partial.createdAt;
    }
  }
}
