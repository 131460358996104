import { Link } from "react-router-dom";
import LogoInit from "../../assets/darkLogo.svg";
import { UserSlideout } from "./UserSlideout";
import { User } from "firebase/auth";
import clsx from "clsx";

export const Header = (props: Props) => {
  const { setUserMenu, userMenu, user } = props;

  return (
    <div
      className={clsx(
        "h-[90px] md:h-[110px]",
        "w-full",
        "bg-white bg-contain bg-top bg-no-repeat md:bg-none!",
        "border-b-solid border-b-[1px] border-b-[#EDEBEB]",
        "relative flex flex-row items-center justify-between",
        "z-10 p-[24px] md:p-0 md:py-[34px]",
        "md:justify-center"
      )}
    >
      <Link to="/">
        <img src={LogoInit} alt="B" className="md:hidden" />
      </Link>
      <Link to="/">
        <h1 className="text-bp-pink font-bold text-xl">
          Programmatic Marketing
        </h1>
      </Link>
      <div
        className="bottom-0 right-8 top-0 flex items-center justify-end md:absolute md:w-[166px]"
        id="header-profile-menu"
      >
        {user && setUserMenu ? (
          <span
            className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-[1px] border-solid border-bp-dkGray text-[20px] font-semibold md:h-[50px] md:w-[50px]"
            onClick={() => setUserMenu(!userMenu)}
          >
            {`${user?.displayName?.charAt(0).toUpperCase() || ""}${
              user?.displayName?.split(" ")[1].charAt(0).toUpperCase() || ""
            }`}
          </span>
        ) : (
          <></>
        )}
      </div>
      {user && userMenu && setUserMenu ? (
        <UserSlideout setOpen={setUserMenu} open={userMenu} user={user} />
      ) : (
        <></>
      )}
    </div>
  );
};

type Props = {
  setUserMenu?: (open: boolean) => void;
  userMenu?: boolean;
  user: User | null;
};
