import clsx from "clsx";
import { useRef } from "react";
import { AriaSelectProps, HiddenSelect, useSelect } from "react-aria";
import { IoCaretUp } from "react-icons/io5";
import { useSelectState } from "react-stately";
import { Button } from "../Buttons/Button";
import { ListBox } from "../InputElements/ListBox";
import { Popover } from "../Popover";
import { IoIosWarning } from "react-icons/io";

type Props<T> = AriaSelectProps<T> & {
  errorMessage?: string;
};

export const Select = <T extends object>(props: Props<T>) => {
  const { errorMessage } = props;
  // Create state based on the incoming props
  const state = useSelectState(props);

  // Get props for child elements from useSelect
  const ref = useRef<HTMLButtonElement>(null);
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref
  );

  return (
    <div className="flex flex-col">
      <label className="text-sm text-bp-indigo" {...labelProps}>
        {props.label}
      </label>
      <HiddenSelect
        isDisabled={props.isDisabled}
        state={state}
        triggerRef={ref}
        label={props.label}
        name={props.name}
      />
      <Button
        {...triggerProps}
        className="mt-2 cursor-pointer justify-normal! border-[1px] border-bp-purple px-4 py-2 font-medium"
        buttonRef={ref}
      >
        <div className="flex w-full flex-row">
          <div className="flex flex-1 justify-start">
            <span
              {...valueProps}
              className={clsx(
                state.selectedItem ? "text-bp-black" : "text-bp-gray"
              )}
            >
              {state.selectedItem ? state.selectedItem.rendered : "Choose one"}
            </span>
          </div>
          <div className="flex items-center justify-center">
            <span aria-hidden="true" className="pl-2">
              <IoCaretUp
                className={`${
                  state.isOpen ? "md:rotate-180" : "md:rotate-0"
                } transition-500 rotate-180 transition-all ease-in-out`}
              />
            </span>
          </div>
        </div>
      </Button>
      {state.isOpen && (
        <Popover
          fullWidth
          state={state}
          triggerRef={ref}
          placement="bottom start"
        >
          <ListBox {...menuProps} state={state} />
        </Popover>
      )}
      {errorMessage && (
        <div className="flex flex-row items-center justify-end px-4">
          <p className="text-xs text-bp-pink md:text-sm">
            <IoIosWarning />
          </p>
          <p className="text-xs text-bp-pink md:text-sm">{errorMessage}</p>
        </div>
      )}
    </div>
  );
};
