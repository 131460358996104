import { ReactNode, useState } from "react";
import { Header } from "./Header";
import { User } from "firebase/auth";
// import { Menu } from "./Menu";

export const Page = (props: Props) => {
  const { user, children } = props;
  const [userMenu, setUserMenu] = useState(false);

  return (
    <div className="absolute top-0 flex h-[100dvh] w-full flex-col overflow-hidden">
      <Header user={user} userMenu={userMenu} setUserMenu={setUserMenu} />
      <div className="flex h-[calc(100dvh-90px)] md:h-[calc(100dvh-110px)]">
        {/* <Menu open={props.menuIsOpen} toggle={props.toggleMenu}>
          {props.menuChildren}
        </Menu> */}
        <div className="relative flex w-full justify-end overflow-hidden">
          <div className="z-1 grow-2 overflow-y-auto pb-[40px] md:mx-[50px] md:pt-16">
            <div className="mx-auto max-w-[1250px] px-[40px]">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

type Props = {
  children: ReactNode;
  menuChildren?: ReactNode;
  menuIsOpen: boolean;
  toggleMenu: () => void;
  user: User | null;
};
